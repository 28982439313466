@import 'src/utils/utils';

.widget {
  @media print {
    display: none;
  }

  p {
    display: block;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    font-size: 150%;
    text-decoration: none;
    color: #ffffff;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;
    width: 100%;
    height: 100%;
    background-color: #737373;
    visibility: hidden;
    opacity: 0;
  }

  &__nav {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999;
    max-width: 400px;
    height: 100%;
    padding-right: 3px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    background-color: #1d2413;
    transition: 0.3s;
    transform: translate(100%, 0);

    ul {
      margin: 0;
      padding: 0;
      padding-bottom: 30px;
      list-style: none;
    }

    li {
      margin: 0;
      padding: 0;

      &:last-of-type {
        margin-bottom: 0;
      }

      a {
        display: block;
        padding-top: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
        padding-left: 30px;
        text-decoration: none;
        color: #ffffff;
        transition: color 0.2s ease-in, background-color 0.2s ease-in;

        &:hover {
          color: #b8b8b8;
          background-color: #191919;
        }
      }

      a.active {
        color: #ffffff;
        background-color: #3d3d3d;
      }
    }
  }

  &__header {
    display: flex;
    height: 60px;
    padding: 18px;
    background-color: #191919;
  }

  &__title {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 1;
    color: #ffffff;
  }

  &__close {
    position: absolute;
    top: 130px;
    left: -25px;
    width: 50px;
    height: 50px;
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 10px;
    background-color: #000000;
    transition: 0.3s;
    box-shadow: 0 0 3px 1px rgba(255, 255, 255, 0.2);
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.5);
    }

    &:before,
    &:after {
      position: absolute;
      top: 23px;
      left: 5px;
      width: 40px;
      height: 3px;
      border-radius: 3px;
      background-color: #ffffff;
      content: '';
      transform: rotate(45deg);
      pointer-events: none;
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  &__body {
    height: calc(100% - 60px);
    overflow: auto;

    &::-webkit-scrollbar-track { // Это сам трек скроллбара
      margin-top: 3px; // маргинами можнно давать отступ, если тебе не надо чтобы скроллбар прижимался к краям
      margin-right: 3px;
      margin-bottom: 3px;
      margin-left: 3px;
      border-radius: 0; // если надо закругленный
      background-color: #1d2413;
    }

    &::-webkit-scrollbar { // это родитель типа для трека + для ползунка
      width: 2px; // ширину скроллбара если он вертикальный
      height: 2px; // высота скроллбара если он горизонтальный
      padding-top: 3px; // тут паддингами надо задавать отступ, я уже не помню маргин или паддинги работают
      padding-right: 3px;
      padding-bottom: 3px;
      padding-left: 3px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb { // это сам ползунок
      border-radius: 0; // если надо закругленный
      background-color: #ffffff;
    }
  }

  &--active {

    .widget__nav {
      transform: translate(0, 0);
    }

    .widget__overlay {
      background-color: rgba(4, 23, 20, 0.9);
      visibility: visible;
      opacity: 1;
      backdrop-filter: blur(5px);
    }
  }
}

.fixed {
  position: relative;
  overflow: hidden;

  // &:before {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   z-index: 20;
  //   width: 100%;
  //   height: 100%;
  //   background-color: rgba(4, 23, 20, 0.9);
  //   backdrop-filter: blur(5px);
  //   content: '';
  // }
}